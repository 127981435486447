import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

export interface IAmpEditInline {
    loading: boolean
}
@Component({
    selector: 'amp-edit-inline',
    templateUrl: './amp-edit-inline.component.html',
    styleUrls: ['./amp-edit-inline.component.css'],
})
export class AmpEditInlineComponent implements OnInit, IAmpEditInline {
    oldValue: string;
    editing: boolean = false;

    newValue: string;

    public loading = false;

    @Input('value') set currentValue(value: string) {
        this.oldValue = value;
        this.newValue = value;
    }

    @Output() saved = new EventEmitter<string>();

    @ViewChild('inputText') inputElement: ElementRef;

    constructor() {}

    ngOnInit() {}

    goToEditMode() {
        this.editing = true;
        this.focusInput();
    }

    emitSave() {
        if ( this.newValue.trim() == "" ) return;
        if ( this.newValue == this.oldValue) return;
        this.saved.emit(this.newValue);
    }

    focusInput() {
        setTimeout(() => {
            this.inputElement.nativeElement.focus();
        }, 0);
    }
}
