import { AmpBaseUrls } from "@shared/AppBaseUrls";
import { AppComponentBase } from "../app-component-base";

export enum AMPColumnType {
	text,
	image,
	action,
	object,
	checkContainer,
	info,
	badge,
	list,
	editable,
	dialog,
	date,
	redirect,
	redirectToEntity,
	remove
}

export type AMPColumns = AMPColumn | AMPImageColumn | AMPActionColumn | AMPObjectColumn | AMPBadgeColumn | AMPInfoColumn | AMPCheckContainerColumn | AMPListColumn | AMPEditableColumn

export enum FilterType {
	search,
	lookUp
}

export interface columnInterface {
    name?: string;
    type?: AMPColumnType;
    width?: string;
    label?: string;
    formatter?: Function;
    classes?: Function;
	tooltip?: Function;
	cases?: Array<badgeCase>;
	actionClass?: string;
	actionList?: Array<AMPActionItem>;
	inObjectName?: string;
	hidden?: boolean;
	objectList?: Function;
	icon?: boolean;
	sorteable?:boolean;

	onClickFunction?: Function;
	context?: AppComponentBase;
}

export class badgeCase {
	case: Function;
	text: string;
}

export class AMPColumn {
	name: string;
	type: AMPColumnType;
	width: string;
	label: string;
	formatter: Function;
	classes: Function;
	hidden: boolean;
	sorteable: boolean;

	constructor({name, type, width, label, formatter, classes, hidden, sorteable } :columnInterface) {
		this.name = name;
		this.type = type;
		this.width = width;
		this.label = label ?? name;
		this.sorteable = sorteable ?? true;
		this.formatter = formatter ?? function formatter(element){
			return element[name[0].toLowerCase() + name.slice(1)]
		};
		this.classes = classes ?? function classes(element){
			return ""
		};
		this.hidden = hidden ?? false;
	}
}

export class AMPImageColumn extends AMPColumn {
	constructor ({name, type, width, label, formatter, classes, hidden}: columnInterface) {
		super({name, type, width, label, formatter, classes, hidden, sorteable: false});
	}
}

export class AMPEditableColumn extends AMPColumn {
	handlerSave: Function;
	constructor ({name, type, width, label, formatter, classes, tooltip, hidden, sorteable}: columnInterface, aHandlerSave: Function) {
		super({name, type, width, label, formatter, classes, hidden, sorteable});
		this.handlerSave = aHandlerSave;
	}
}

export class AMPActionItem {
	name?: string;
	icon?: string;
	permission?: string;
	goTo?: string;
	onClickFunction?: any;
	context?: AppComponentBase;
	aditionalIcon?: Icon;
	condition?: Function;
	blocked?: Function;
	edit?: boolean;
	lineBreak?: boolean;

	constructor(name: string, icon:string, permission:string, context: AppComponentBase, aditionalIcon: Icon, edit: boolean, lineBreak: boolean, condition?: Function, blocked?: Function, goTo?:string, onClickFunction?: any){
		this.name = name;
		this.icon = icon;
		this.permission = permission;
		this.goTo = goTo ?? null;
		this.onClickFunction = onClickFunction ?? null;
		this.context = context;
		this.aditionalIcon = aditionalIcon ?? new Icon();
		this.condition = condition ?? function condition(element){
			return true;
		};
		this.blocked = blocked ?? function blocked(element){
			return false;
		};
		this.edit = edit ?? false
		this.lineBreak = lineBreak ?? false
	}
}

export class Icon {
	icon: string;
	tooltip?: Function;
	constructor(icon?: string, tooltip?: Function){
		this.icon = icon ?? "";
		this.tooltip = tooltip ?? function tooltip(element, action){
			return ""
		};
	}
}


export class AMPActionColumn extends AMPColumn {
	
	actionList: Array<AMPActionItem>;
	actionClass: string
	constructor ({name, type, width, actionList, label, formatter, classes, actionClass, hidden, sorteable}: columnInterface) {
		super({name, type, width, label, formatter, classes, hidden, sorteable});

		this.actionList = actionList;
		this.actionClass = actionClass
	}
}

export class AMPObjectColumn extends AMPColumn {
	name:string;
	inObjectName: string;
	type: AMPColumnType;
	width: string;
	formatter: Function;
	constructor({name, inObjectName, type, width, label, formatter, classes, hidden, sorteable}: columnInterface) {
		super({name, type, width, label, formatter, classes, hidden, sorteable});

		this.inObjectName = inObjectName;
		this.formatter = formatter ?? function formatter(element){
			return element[name[0].toLowerCase() + name.slice(1)][inObjectName]
		}
	}
}

export class AMPRedirectToEntity extends AMPColumn {
	name:string;
	type: AMPColumnType;
	width: string;
	formatter: Function;
	getterId: Function;
	entityToRedirect: string;
	resolvePath?: (x : any) => string[];
	
	constructor({name, inObjectName, width, label, formatter, classes, hidden, sorteable}: columnInterface, entity:string, aGetterIdFn: Function, resolvePath?: (x : any) => string[]) {
		const type = AMPColumnType.redirectToEntity
		super({name, type, width, label, formatter, classes, hidden, sorteable});
		this.formatter = formatter ?? function formatter(element){
			return element[name[0].toLowerCase() + name.slice(1)][inObjectName]
		}
		this.entityToRedirect = entity;
		this.getterId = aGetterIdFn;
		this.resolvePath = resolvePath;
	}
}

export class AMPFilterData {
	filter: string;
	name: string;
	value: string;
}
export class AMPFilter {
	name: string;
	width: string;
	type: FilterType;
	data?: AMPFilterData;

	constructor (name: string, width: string, type: FilterType, data?: AMPFilterData){
		this.name = name;
		this.width = width;
		this.type = type;
		this.data = data;
	}
}

export class AMPCheckContainerColumn extends AMPColumn{
	tooltip : Function;
	constructor ({name, type, width, label, formatter,classes, tooltip, hidden, sorteable}: columnInterface) {
		super({name, type, width, label, formatter, classes, hidden, sorteable});
		this.tooltip = tooltip
	}
}

export class AMPInfoColumn extends AMPColumn{
	tooltip: Function;
	constructor ({name, type, width, label, formatter,classes, tooltip, hidden, sorteable}: columnInterface) {
		super({name, type, width, label, formatter, classes, hidden, sorteable});
		this.tooltip = tooltip
	}
}

export class AMPBadgeColumn extends AMPColumn{
	cases : Array<badgeCase>
	constructor ({name, type, width, label, formatter, classes, tooltip, cases, hidden, sorteable}: columnInterface) {
		super({name, type, width, label, formatter, classes, hidden, sorteable});
		this.cases = cases
	}
}

export class AMPDateColumn extends AMPColumn{
	constructor ({name, type, width, label, formatter,classes, tooltip, hidden, sorteable}: columnInterface) {
		super({name, type, width, label, formatter, classes, hidden, sorteable});
	}
}

export class AMPRedirectColumn extends AMPColumn{
	onClickFunction: Function;
	context: AppComponentBase
	constructor ({name, type, width, label, formatter,classes, tooltip, hidden, onClickFunction, context, sorteable}: columnInterface) {
		super({name, type, width, label, formatter, classes, hidden, sorteable});
		this.onClickFunction = onClickFunction;
		this.context = context;
	}
}

export class AMPListColumn extends AMPColumn{
	objectList : Function
	icon: boolean
	constructor ({name, type, width, label, formatter, classes, objectList, icon, hidden, sorteable}: columnInterface) {
		super({name, type, width, label, formatter, classes, hidden, sorteable});
		this.objectList = objectList
		this.icon = icon
	}

	
}

export class AMPButton extends AMPColumn{
	onClickFunction : Function
	context: AppComponentBase;
	constructor ({name, type, width, label, formatter, classes, objectList, icon, hidden, sorteable, onClickFunction, context}: columnInterface) {
		super({name, type, width, label, formatter, classes, hidden, sorteable});
		this.onClickFunction = onClickFunction;
		this.context = context;
	}
}

export class AMPTableConfiguration{
	columnDefinition: AMPColumns[];
	filterList: AMPFilter[];
	displayedRows: string[];
	constructor(columnDefinition:AMPColumns[], filterList:AMPFilter[], displayedRows:string[]){
		this.columnDefinition = columnDefinition,
		this.filterList = filterList
		this.displayedRows = displayedRows
	}
}