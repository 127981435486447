import { ChangeDetectorRef, Component, ElementRef, HostListener, Input, OnInit, Renderer2, ViewChild, forwardRef } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { NameValueDto, PagedResultDtoOfNameValueDto } from '@shared/service-proxies/service-proxies';
import { Observable, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, finalize, map, shareReplay, switchMap, tap } from 'rxjs/operators';

@Component({
    selector: 'amp-autocomplete',
    templateUrl: './amp-autocomplete.component.html',
    styleUrls: ['./amp-autocomplete.component.css'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => AmpAutocompleteComponent),
            multi: true,
        },
    ],
})
export class AmpAutocompleteComponent implements OnInit, ControlValueAccessor {
    /**
     * Form control label
     */
    @Input() label: string;
    
    @Input() idFormatter: Function;

    /**
     * To show clear button
     */
    @Input() clearable: boolean = false;

    value: NameValueDto;


    valueMultiple: NameValueDto[];

    @Input() isRequired: boolean = false;

    /**
     * Obsevable listening submir in form parent
     * @description This is used to mark the control as touched when the form is submitted (required to show Required Validation)
     */
    @Input() submitEvent$ = new Observable<void>();

    /**
     * Function that returns the data source (observable with data items)
     * @description The function will send request to get items, it has no subscribe because
     * it is used in async pipe (View HTML)
     */
    @Input() dataSourceFn: (body: string) => Observable<PagedResultDtoOfNameValueDto>

    /**
     * Minimum length to start searching, is optional.
     * When it is not set, the search will start when the user types the first character
     * If it is set, the search will start when the user types the number of characters set
     */
    @Input() minLengthToSearch: number;

    @Input() multiple: boolean = false;

    /**
     * List of chips to show, it will always be a list of one element (if one is selected)
     */
    chips: string[] = [];

    /**
     * To show loading spinner, it is set to true when the user types in the input
     */
    isLoading = false;
    noResults: boolean = false;

    submitSub: Subscription;
    options$: Observable<NameValueDto[]>;
    optionsSearch$: Observable<NameValueDto[]>;

    myControl: FormControl = new FormControl();
    textInputControl: FormControl = new FormControl();

    inputValueChanges: Subscription;

    isSearch = false;

    @ViewChild('chipInputText', {static: false}) chipInputText: ElementRef<HTMLInputElement>;

    constructor(private cdRef: ChangeDetectorRef) {}

    onChange = (value: any) => {};
    onTouched: any = () => {};

    writeValue(obj: any): void {
        if(!obj) {
            this.clearInput();
            return;
        };
        this.value = obj;

        this.myControl.setValue(obj);
        if(!this.multiple){
            this.chips = [`${obj.name}`];
        }
        else{
            this.chips = obj.map(obj => obj.name)
        }
        this.cdRef.detectChanges();
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }
    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
        if (isDisabled) {
            this.myControl.disable();
            this.textInputControl.disable();
        } else {
            this.myControl.enable();
            this.textInputControl.enable();
        }
    }

    ngOnInit() {
        this.submitSub = this.submitEvent$.subscribe(() => {
            this.myControl.markAsTouched();
            this.myControl.updateValueAndValidity();
            this.cdRef.detectChanges();
        })
        if(!this.multiple){
            this.inputValueChanges = this.textInputControl.valueChanges.subscribe(()=>{
                this.isSearch = false;
            })
        }

        this.options$ = this.textInputControl.valueChanges.pipe(
            shareReplay(1),
            distinctUntilChanged(),
            filter((name) => this.minLengthToSearch ?  name.length >= this.minLengthToSearch : true),
            debounceTime(500),
            tap((name) => {
                if (!!name && typeof name === 'string') {
                    this.isLoading = true;
                }
            }),
            filter((name) => !!name && typeof name === 'string'),
            switchMap((name) => {
                return this.dataSourceFn(name).pipe(
                    finalize(() => (this.isLoading = false)),
                    map((result) => result.items),
                    tap((items) => {
                        this.noResults = items.length === 0;
                    })
                );
            })
        );
    }

    changeF(selectedValue: MatAutocompleteSelectedEvent) {

        this.onChange(selectedValue.option.value);
        if(!this.multiple){
            this.chips = [selectedValue.option.value.name];
            this.myControl.setValue([selectedValue.option.value])
        }
        else{
             if ( this.chips.find( c => c == selectedValue.option.value.name )) return;
            this.chips.push(selectedValue.option.value.name);
            this.myControl.setValue([...this.myControl.value, selectedValue.option.value]);
        }
        this.cdRef.detectChanges();
    }

    getMultipleValues(){
        return this.myControl.value ? this.myControl.value : [];

    }

    displayFn(value: NameValueDto | string): (value: any) => string | null {
        return value ? value['name'] ?? value : null;
    }

    deleteSelection(deleted?) {
        if(!this.multiple){
            this.clearInput();
        }
        else{
            this.chips = this.chips.filter(v => v != deleted);
            this.myControl.setValue(this.myControl.value.filter(v => v.name != deleted));
            if(this.chips.length == 0){
                this.myControl.setValue('');
                this.onChange(null);
            }
        }

        this.cdRef.detectChanges();
    }

    clearInput(){
        this.myControl.setValue('');
        this.chips = [];
        this.onChange(null);
    }

    ngOnDestroy(): void {
        this.submitSub?.unsubscribe();
        this.inputValueChanges?.unsubscribe();
    }

    closedAutocompletePanel(){
        this.chipInputText.nativeElement.value = '';
        this.textInputControl.setValue('');
    }

    showFirstElements(){
        if(this.multiple){
            this.chipInputText.nativeElement.focus()
        }
        this.isLoading = true;
        this.isSearch = true;
        this.optionsSearch$ = this.dataSourceFn('').pipe(
            finalize(() => (this.isLoading = false)),
            map((result) => result.items),
            tap((items) => {
                this.noResults = items.length === 0;
            })
        );
    }

    searchByKeyCombination(){
        if(!this.textInputControl.value){
            this.showFirstElements();
        }
    }
}


