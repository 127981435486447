import { Component, ViewChild, Injector, Inject} from '@angular/core';

import {
    MessagingModelServiceProxy,

    MessageDto,
    MessageServiceProxy,
    CreateOrUpdateMessagingModelInput,
} from '@shared/service-proxies/service-proxies';
import { AppComponentBase } from '@shared/common/app-component-base';
import { FormBuilder, FormGroup, Validators, FormArray, FormControl, NG_ASYNC_VALIDATORS } from '@angular/forms';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NotifyService } from 'abp-ng2-module';
import { noWhitespaceValidator } from '@shared/helpers/ValidationHelpers';
 
@Component({
    selector: 'editMessagingModelModal',
    templateUrl: './edit-messaging-model-modal.component.html',
    styleUrls: ['./edit-messaging-model-modal.component.less'],
    //providers: [MatDialogRef],
})
export class EditMessagingModelModalComponent extends AppComponentBase {
    @ViewChild(MatTable) table: MatTable<any>;
    active = false;
    saving = false;
    public notify: NotifyService;
    newMessages: MessageDto[];
    messaginModelData: any;
    form: FormGroup;
    displayedColumns: string[] = ['type', 'content', 'active', 'provisionerId']
    datasource: MatTableDataSource<MessageDto>;
    provisionerMessages: MessageDto[];
    subscription: any;
    lastSelection: MessageDto[] = [];
    messagingModelName: string;

    constructor(
        injector: Injector,
        public dialogRef: MatDialogRef<EditMessagingModelModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,

        private _messagingModelServiceProxy: MessagingModelServiceProxy,
        private _messagingAppservice: MessageServiceProxy,
        private formBuilder: FormBuilder
    ) {
        super(injector);
        this.notify = injector.get(NotifyService);
        this.messaginModelData = this.data;
        this.newMessages = this.data.messages ?? [];

    }

    setForm() {
        let name = this.messaginModelData?.name || '';

        let messages = this.newMessages.map( x => x.id.toString());
        let sendContentMessageOnSubscription = this.messaginModelData?.sendContentMessageOnSubscription != undefined ? this.messaginModelData?.sendContentMessageOnSubscription : true;

        this.form = this.formBuilder.group({
            name: [name, [Validators.required]],
            messages: [this.provisionerMessages],
            messagesForm: [messages],
            sendContentMessageOnSubscription: [sendContentMessageOnSubscription]
        });
        this.lastSelection = this.newMessages;
    }

    get f() {
        return this.form.controls;
    }
    submitted: boolean = false;

    ngOnInit() {
        this.setForm();
        this._messagingAppservice.getMessagesListByProvisioner(this.data.provisionerId).subscribe((element) => {
            this.provisionerMessages = element;

        });
    }

    close(): void {
        this.dialogRef.close();
    }

    selectionChange(param) {
        let selectedMessages = [];
        param.value.forEach((id) => {
            let messageSelect = this.provisionerMessages.filter((message) => {
                return message.id === Number(id);
            });
            selectedMessages.push(messageSelect[0]);
        });

        if (selectedMessages.length > this.lastSelection.length) {
            let messagesToAdd = selectedMessages.filter(
                (message) => !this.lastSelection.some(({ id }) => message.id === id)
            );

            messagesToAdd.forEach((message) => {
                this.newMessages.push(message);
            });
        } else {
            let messagesToErase = this.lastSelection;
            if (selectedMessages.length != 0) {
                selectedMessages.forEach((message) => {
                    messagesToErase = messagesToErase.filter((messageDelete) => {
                        return messageDelete.id !== message.id;
                    });
                });
            }

            messagesToErase.forEach((deleteMessage) => {
                this.newMessages = this.newMessages.filter((newMessage) => {
                    return newMessage.id !== deleteMessage.id;
                });
            });
        }
        this.lastSelection = selectedMessages;
        this.table.renderRows();
        return null;
    }

    delete(params) {
        let index = this.newMessages.indexOf(params, 0);
        this.newMessages = this.newMessages
            .slice(0, index)
            .concat(this.newMessages.slice(index + 1, this.newMessages.length));
        this.table.renderRows();
        this._messagingAppservice.getMessagesListByProvisioner(this.data.provisionerId).subscribe((element) => {
            this.provisionerMessages = element;
            this.newMessages.forEach((newMessage) => {
                this.provisionerMessages = this.provisionerMessages.filter((message) => {
                    return message.id !== newMessage.id;
                });
            });
        });
        return null;
    }

    save() {
        let name = this.form.controls['name'].value;
        this.messagingModelName = name.trim();
        if(this.form.invalid || this.messagingModelName == ""){
            return;
        }

        let messagingModel = new CreateOrUpdateMessagingModelInput();
        messagingModel.name = this.messagingModelName;
        messagingModel.messages = this.newMessages;
        messagingModel.provisionerId = this.data.provisionerId;
        messagingModel.id = this.messaginModelData.id ?? 0;
        messagingModel.sendContentMessageOnSubscription = this.form.value.sendContentMessageOnSubscription;



        this._messagingModelServiceProxy.createOrUpdateMessagingModel(messagingModel).subscribe((element) => {
            this.notify.success('Saved messaging model');
            this.dialogRef.close();
        });

    }
}
